/*global $*/
/*eslint no-undef: "error"*/

import Swiper from 'swiper'
import {Autoplay, EffectCoverflow, Navigation, Pagination, Thumbs} from 'swiper/modules'
Swiper.use([Navigation, Pagination, EffectCoverflow, Autoplay, Thumbs])

export default class SwiperSlider {
    createProductPopular () {
        return new Swiper('#slider--product-popular', {
            slidesPerView: 4,
            spaceBetween: 24,
            // loop: true,
            navigation: {
                nextEl: '.slider--product-popular-next',
                prevEl: '.slider--product-popular-prev',
            },
            pagination: {
                el: '.swiper-pagination--product-popular',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 2,
                    spaceBetween: 16,
                },
                514: {
                    slidesPerView: 2,
                    spaceBetween: 16
                },
                640: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1240: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
                1440: {
                    slidesPerView: 4,
                    spaceBetween: 24,
                }
            }
        })
    }
    createArticle () {
        return new Swiper('#slider--article', {
            slidesPerView: 3,
            spaceBetween: 10,
            // loop: true,
            navigation: {
                nextEl: '.slider--article-next',
                prevEl: '.slider--article-prev',
            },
            pagination: {
                el: '.swiper-pagination--article',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                320: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                360: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                375: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                414: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                480: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                },
                514: {
                    slidesPerView: 1,
                    spaceBetween: 10
                },
                640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                768: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                991: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                1240: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                1440: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                }
            }
        })
    }
    createProductGallery () {
        return new Swiper('#slider--product-gallery', {
            slidesPerView: 4,
            spaceBetween: 24,
            // loop: true,
            navigation: {
                nextEl: '.slider--product-gallery-next',
                prevEl: '.slider--product-gallery-prev',
            },
            pagination: {
                el: '.swiper-pagination--product-gallery',
                clickable: true
            },
            breakpoints: {
                0: {
                    slidesPerView: 1.5,
                    spaceBetween: 5,
                },
                320: {
                    slidesPerView: 1.5,
                    spaceBetween: 5,
                },
                360: {
                    slidesPerView: 1.5,
                    spaceBetween: 5,
                },
                375: {
                    slidesPerView: 1.5,
                    spaceBetween: 5,
                },
                414: {
                    slidesPerView: 1.5,
                    spaceBetween: 5,
                },
                480: {
                    slidesPerView: 1.5,
                    spaceBetween: 5,
                },
                514: {
                    slidesPerView: 2.5,
                    spaceBetween: 5
                },
                640: {
                    slidesPerView: 2.5,
                    spaceBetween: 5,
                },
                768: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                991: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                1024: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                },
                1240: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                },
                1440: {
                    slidesPerView: 4,
                    spaceBetween: 10,
                }
            }
        })
    }

    constructor () {
        if (parseInt($('#slider--product-popular').length) > 0) {
            this.createProductPopular()
        }

        if (parseInt($('#slider--article').length) > 0) {
            this.createArticle()
        }

        if (parseInt($('#slider--product-gallery').length) > 0) {
            this.createProductGallery()
        }
    }
}
